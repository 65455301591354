import React, { useState, useEffect } from "react";
import Homebarpage from "../Components/Homebarpage";
import '../Styles/Homedash.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import HomeFooter from "../Components/HomeFooter";
import BGContainer from "../Components/BGContainer";
const apiUrl = process.env.REACT_APP_API_URL_LOCAL;

// Sample categories data to illustrate
const sampleCategories = [
    { id: 1, category: { id: 1, category_board: "Categoria 1" } },
    { id: 2, category: { id: 2, category_board: "Categoria 2" } },
    { id: 3, category: { id: 3, category_board: "Categoria 3" } },
    { id: 4, category: { id: 4, category_board: "Categoria 4" } },
    { id: 5, category: { id: 5, category_board: "Categoria 5" } },
    { id: 6, category: { id: 6, category_board: "Categoria 6" } },
    { id: 7, category: { id: 7, category_board: "Categoria 7" } },
];

function CategoriesSection({ categoriesData }) {

    // Check if categoriesData is valid and has data
    if (!categoriesData || categoriesData.length === 0) {
        return (
            <div className="categories-section empty-message">
                <h4>Categorías</h4>
                <p>Tienes que hacer un aporte para activar la primera categoría</p>
            </div>
        );
    }

    // Determine the highest category ID (using category_display instead of category)
    const validCategories = categoriesData.filter(item => item && item.category_display);
    const highestCategoryId = Math.max(...validCategories.map(item => item.category_display.id));

    return (
        <div className="categories-section">
            <h4>Categorías</h4>
            <div className="categories-container">
                {validCategories.map(item => {
                    if (!item?.category_display) {
                        console.warn("Skipping invalid item:", item);
                        return null; // Skip invalid items
                    }

                    const categoryId = item.category_display.id;
                    let categoryBoard = item.category_display.category_board || 'Sin Nombre';

                    // Convert "category_1" to "Categoria 1"
                    if (categoryBoard.startsWith("category_")) {
                        const categoryNumber = categoryBoard.split("_")[1];
                        categoryBoard = `Categoria ${categoryNumber}`;
                    }

                    // Determine color based on the category ID
                    const circleColor = categoryId === highestCategoryId 
                        ? '#415d59' // Active
                        : categoryId < highestCategoryId 
                        ? '#415d59' // Completed
                        : '#89715d'; // Locked

                    return (
                        <div 
                            key={item.id} 
                            className="category-circle" 
                            style={{ backgroundColor: circleColor }}
                            aria-label={`Categoría ${categoryBoard}`}
                        >
                            <p>{categoryBoard}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}



function HomeDash() {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [user, setUser] = useState(null); // State for user data
    const [categoriesData, setCategoriesData] = useState(sampleCategories); // Sample categories data
    const [selectedCategory, setSelectedCategory] = useState(1); // State for selected category
    const [attributorInfo, setAttributorInfo] = useState(null);
    const [categoryInfo, setCategoryInfo] = useState(null);
    const [valueInfo, setValueInfo] = useState(null);
    const [reciverId, setReciverId] = useState(null);
    const [attributorId, setAttributorId] = useState(null);
    const [categoryId, setCategoryId] = useState(null);
    const [circleContent, setCircleContent] = useState(Array(5).fill(null));
    const [referralLink, setReferralLink] = useState('');
    const [receivedContributions, setReceivedContributions] = useState(0);
    const [posFound, setPosFound] = useState(null);

    useEffect(() => {
        AOS.init({ duration: 2000 });

        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('accessToken'); 

        if (userId && token) {
            fetchUserData(userId, token);
            fetchCategoriesData(userId, token);
            fetchReceivedContributions(userId, token);
            fetchUserPosition(userId, token);
        }

        const fetchData = async () => {
            const circles = await updateSmallCircles();
            setCircleContent(circles);
        };
        fetchData();

    }, []);

    const fetchUserData = (userId, token) => {
        fetch(`${apiUrl}api/users/${userId}/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            if (!response.ok) {
                // Check for 4xx or 5xx responses and handle accordingly
                throw new Error('Failed to fetch user data: ' + response.statusText);
            }
            return response.json();
        })
        .then(data => {
            // Handle the case if no data is returned
            if (data) {
                setUser(data);  // Assuming response returns a single user object

                const userReferralLink = `${window.location.origin}/register?ref=${userId}`;
                setReferralLink(userReferralLink);
            }
        })
    };

    const copyReferralLink = () => {
        navigator.clipboard.writeText(referralLink)
            .then(() => {
                alert("Referral link copied to clipboard!");
            })
            .catch((err) => {
                alert("Failed to copy referral link. Try again.");
            });
    };

    const fetchCategoriesData = (userId, token) => {
        fetch(`${apiUrl}api/tail/?user=${userId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            // Filter out valid items with a valid category_display
            const validCategories = data.filter(item => item && item.category_display);
            setCategoriesData(validCategories);
    
            if (validCategories.length > 0) {
                // Find the highest category ID from category_display.id
                const highestCategory = Math.max(...validCategories.map(item => item.category_display.id));
                setSelectedCategory(highestCategory + 1); // Set to the next category
            } else {
                setSelectedCategory(1); // Default to 1 if no valid categories
            }
        })
    };
    
    const handleModalOpen = async () => {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('accessToken');
    
        if (!userId || !token) return;
    
        let tailId = null;
        let nextCategory = 1;
        let fieldTail = 'tail_pos';
        let atributtorsResponse = null;
    
        try {
            // Step 1: Fetch the tail data to determine the next category
            const tailResponse = await fetch(`${apiUrl}api/tail/?user=${userId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
    
            if (!tailResponse.ok) {
                throw new Error(`Failed to fetch tail data: ${tailResponse.status} ${tailResponse.statusText}`);
            }

            const tailData = await tailResponse.json();

            if (tailData.length > 0) {
                const lastCategory = tailData[0].category_display.id;
                nextCategory = lastCategory + 1;

                if (nextCategory === 4) {
                    try {
                        const referralsResponse = await fetch(`${apiUrl}api/referrals/?referrer=${userId}`, {
                            method: 'GET',
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        });
                        const referralsData = await referralsResponse.json();
    
                        if (referralsData.length >= 2) {
                            
                        } else {
                            alert("Tienes que referir a 2 personas para poder continuar.");
                        }
                    } catch (error) {
                        alert('Hubo un error verificando las referencias. Intenta de nuevo más tarde.');
                    }
                }

            } else {

            }
    
            // Step 2: Check if the user is already in tail with the lastCategory and status "pending"
            const tailCheckResponse = await fetch(`${apiUrl}api/tail/?user=${userId}&status=pending&category_board=category_${nextCategory - 1}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
    
            const tailCheckData = await tailCheckResponse.json();
            if (tailCheckData.length > 0) {
                alert("Ya estás en la cola.");
                return; // Stop further execution if the user is already in the queue
            }

            const tailWithResponse = await fetch(`${apiUrl}api/tail/?user=${userId}&status=finish&category_board=category_${nextCategory - 1}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
    
            const tailWithData = await tailWithResponse.json();

            if (tailWithData.length > 0) {
                const contributionsResponse = await fetch(`${apiUrl}api/contributions/?level_category=${nextCategory - 1}&user_boss=${userId}&status=acepted`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
            
                if (!contributionsResponse.ok) {
                    throw new Error(`Failed to fetch contributions: ${contributionsResponse.status} ${contributionsResponse.statusText}`);
                }
            
                const contributionsData = await contributionsResponse.json();
            
                // Check if the length is equal to 5
                if (contributionsData.length !== 5) {
                    alert("Debes recibir 5 aportes para avanzar de nivel");
                    return; // Stop further execution if contributions are less than 5
                }
            }
    
            // Step 3: Create the contribution if the user is not already in the tail
            const createResponse = await fetch(`${apiUrl}api/tail/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user: userId,
                    status: 'pending',
                    category: nextCategory,
                }),
            });
    
            if (!createResponse.ok) {
                throw new Error(`Failed to create contribution: ${createResponse.status} ${createResponse.statusText}`);
            }
    
            const createResult = await createResponse.json();
            tailId = createResult.id;
    
            // Step 4: Check for the last chosen data
            const chosenResponse = await fetch(`${apiUrl}api/chosen/last/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
    
            const chosenData = await chosenResponse.json();
    
            let chosenId = null;
    
            if (chosenData.message && chosenData.message === "No Chosen objects found.") {
                // Create a new entry if no chosen object found
                const newChosenResponse = await fetch(`${apiUrl}api/chosen/`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },

                    
                    body: JSON.stringify({
                        tail_pos_1: tailId, // Update the field name to "tail_pos_1" instead of "pos1"
                        tail_pos_2: null,
                        tail_pos_3: null,
                        tail_pos_4: null,
                        tail_pos_5: null,
                        tail_pos_6: null,
                        tail_pos_7: null,
                    }),
                });
                const newChosenData = await newChosenResponse.json();
                chosenId = newChosenData.id;
            } else {
                // If a chosen object exists, find the first null position field
                let firstNullPosition = null;
    
                for (let i = 1; i <= 7; i++) {
                    const posKey = `tail_pos_${i}`;
                    if (chosenData[posKey] === null) {
                        firstNullPosition = posKey;
                        break;
                    }
                }
    
                chosenId = chosenData.id;
    
                if (firstNullPosition) {
                    // Update the first null position with the tailId
                    const updateBody = {
                        [firstNullPosition]: tailId
                    };
    
                    const updateResponse = await fetch(`${apiUrl}api/chosen/`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(updateBody),
                    });
                    const updateData = await updateResponse.json();
                } else {

                }
            }
    
            // Step 5: Check or Create Atributors object
            const atributorsResponse = await fetch(`${apiUrl}api/atributors/last/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
    
            const atributorsData = await atributorsResponse.json();
            let atributorsId = null;
    
            if (atributorsData.message === "No Atributors objects found.") {
                // No Atributors object found, so create one
                const newAtributorsResponse = await fetch(`${apiUrl}api/atributors/`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        tail_pos1: tailId
                    }),
                });
                const newAtributorsData = await newAtributorsResponse.json();
                atributorsId = newAtributorsData.id;
                fieldTail = 'tail_pos1';
                atributtorsResponse = newAtributorsData;
            } else {
                // Update the first null field in existing Atributors object
                atributorsId = atributorsData.id;
    
                for (let i = 1; i <= 30; i++) {
                    const field = `tail_pos${i}`;
                    if (atributorsData[field] === null) {
                        const updateBody = {};
                        updateBody[field] = tailId;
                
                        // Make PATCH request to update the first null field
                        const patchResponse = await fetch(`${apiUrl}api/atributors/${atributorsId}/`, {
                            method: 'PATCH',
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(updateBody),
                        });
                
                        if (!patchResponse.ok) {
                            break; // Exit if there's an error
                        }
                
                        // Parse and print the JSON response
                        const patchResult = await patchResponse.json();
                
                        fieldTail = field; // Save the updated field

                        atributtorsResponse = patchResult;

                        break; // Exit the loop after updating
                    }
                }
            }
    
            // Step 6: Fetch the process data with the updated atributorsId and chosenId
            try {
                const processResponse = await fetch(`${apiUrl}api/process/?atributors_id=${atributorsId}&chosen_id=${chosenId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
    
                if (!processResponse.ok) {
                    throw new Error(`Failed to fetch process data: ${processResponse.status} ${processResponse.statusText}`);
                }
            
                const processData = await processResponse.json();
                const fieldTail2 = fieldTail.replace(/(\D+)(\d+)/, "$1_$2");

                if (fieldTail) {
                    // Access the nested structure within processData
                    
                    const attributor_info = processData[0][fieldTail2];
                    const user_category = atributtorsResponse[fieldTail].category_display?.category_board;
                    const user_category_value = atributtorsResponse[fieldTail].category_display?.value;

                    setReciverId(processData[0][fieldTail2].id);
                    setAttributorId(atributtorsResponse[fieldTail].user_display?.id);
                    setCategoryId(atributtorsResponse[fieldTail].category_display?.id);
                    
                    if (user_category ) {
                        // Transform "category_1" to "Categoria 1"
                        const formattedCategory = user_category.replace("category_", "Categoria ");
                        setCategoryInfo(formattedCategory);

                    }

                    if (user_category_value !== undefined) {
                        // Format the number to 2 decimal places and append " $USD"
                        const formattedValue = `${user_category_value.toFixed(2)} $USD`;
                        setValueInfo(formattedValue);
                    }


                    if (attributor_info) {
                        setAttributorInfo(attributor_info);
                    }

                } else {
                }

    
            } catch (error) {
                alert("Error fetching process data. Please try again.");
            }
    
            // Open the modal after successful completion of all steps
            setIsModalVisible(true);
    
        } catch (error) {
            alert("Hubo un error al crear el aporte. Por favor, inténtalo de nuevo.");
        }
    };
    
    
    

    const handleModalClose = async () => {
        const token = localStorage.getItem('accessToken');
        const userId = localStorage.getItem('userId');
    
        try {
            // Fetch data from the chosen endpoint
            const chosenResponse = await fetch(`${apiUrl}api/chosen/by-user/?user_display=${userId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
    
            if (chosenResponse.ok) {
                const chosenData = await chosenResponse.json();
                let chosenResult = [];
    
                // Directly check each position for matching userId
                chosenData.forEach(item => {
                    Object.entries(item).forEach(([pos, tail]) => {
                        if (pos.startsWith('tail_pos_')) {
                            // Log each tail's user_display.id and the comparison value
                            const tailUserId = tail?.user_display?.id;
                            
                            // Ensure the comparison is done with matching types (convert to string)
                            if (String(tailUserId) === String(userId)) {
                                chosenResult.push({
                                    chosenId: item.id,
                                    positionId: tail.id,
                                    position: pos
                                });
                            }
                        }
                    });
                });
    
                // Second request to fetch data from the atributors endpoint
                const attributorsResponse = await fetch(`${apiUrl}api/atributors/by-user/?user_display=${userId}&status=pending`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
    
                if (attributorsResponse.ok) {
                    const attributorsData = await attributorsResponse.json();
                    let attributorsResult = [];
                    
                    // Directly check each tail position for matching userId
                    attributorsData.forEach(item => {
                        for (let i = 1; i <= 34; i++) {
                            const tailPosKey = `tail_pos${i}`;
                            const tail = item[tailPosKey];
                            
                            if (tail && tail.user_display?.id === Number(userId)) {
                                
                                attributorsResult.push({
                                    atributorsId: item.id,
                                    tailPositionId: tail.id,
                                    tailPosition: tailPosKey
                                });
                            }
                        }
                    });

                    // DELETE request for matching attributors position
                    if (attributorsResult.length > 0) {
                        const attributor = attributorsResult[0]; // Only delete the first matching attributors position
                        const deleteUrl = `${apiUrl}api/atributors/${attributor.atributorsId}/delete-tail/`;
                        const deleteBody = JSON.stringify({ tail_position: attributor.tailPosition });
    
                        try {
                            const deleteTailResponse = await fetch(deleteUrl, {
                                method: 'DELETE',
                                headers: {
                                    'Authorization': `Bearer ${token}`,
                                    'Content-Type': 'application/json',
                                },
                                body: deleteBody,
                            });

                            if (deleteTailResponse.ok) {
                            } else {
                            }
                        } catch (error) {
                        }
                    }
    
                    // DELETE request for matching chosen position
                    if (chosenResult.length > 0) {
                        const chosen = chosenResult[0]; // Only delete the first matching chosen position
                        const url = `${apiUrl}api/chosen/${chosen.chosenId}/`;
                        const body = JSON.stringify({ [chosen.position]: `${chosen.positionId}` });
    
                        try {
                            const deleteResponse = await fetch(url, {
                                method: 'DELETE',
                                headers: {
                                    'Authorization': `Bearer ${token}`,
                                    'Content-Type': 'application/json',
                                },
                                body: body,
                            });
    
                            const responseData = await deleteResponse.text();

                            if (responseData) {
                                // After successfully deleting the attributor's tail, delete the corresponding tail
                                const tailDeleteUrl = `${apiUrl}api/tail/${chosen.positionId}/`;
                                try {
                                    const deleteTailFinalResponse = await fetch(tailDeleteUrl, {
                                        method: 'DELETE',
                                        headers: {
                                            'Authorization': `Bearer ${token}`,
                                            'Content-Type': 'application/json',
                                        },
                                    });
                    
                                    const finalResponseData = await deleteTailFinalResponse.text();
                                    if (deleteTailFinalResponse.ok) {
                                        
                                    } else {
                                    }
                                } catch (error) {
                                }
                    
                            }
                            if (deleteResponse.ok) {

                            } else {
                            }
                        } catch (error) {
                        }
                    }   
                } else {
                }
            } else {
            }
        } catch (error) {
        }
    
        setIsModalVisible(false);
    };
    

    const handleCreateContribution = async () => {
        const token = localStorage.getItem('accessToken');
        const contributionUrl = `${apiUrl}api/contributions/`;
        const userNotificationUrl = `${apiUrl}api/user_notifications/`;
    
        // Define the request body for the contribution
        const contributionRequestBody = {
            user_boss: reciverId,       // Replace with actual receiver ID variable
            user_refer: attributorId,   // Replace with actual attributor ID variable
            level_category: categoryId, // Replace with actual category ID variable
            status: "pending"
        };
    
        try {
            // Send POST request to create the contribution
            const contributionResponse = await fetch(contributionUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(contributionRequestBody)
            });
    
            if (contributionResponse.ok) {
                // Prepare the request body for the user notification
                const notificationRequestBody = {
                    user: attributorInfo.id,        // ID of the user receiving the notification
                    notification_chose: 1,          // Example notification choice ID
                    status: false,                  // Initial notification status as unread
                    destinatary: attributorId
                };

                // Send POST request to create the user notification
                const notificationResponse = await fetch(userNotificationUrl, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(notificationRequestBody)
                });
    
                if (notificationResponse.ok) {
                    const notificationData = await notificationResponse.json();
                } else {
                }
            } else {
            }
        } catch (error) {
        }

        setIsModalVisible(false);
        window.location.reload();
    };

    const updateSmallCircles = async () => {
        try {
            const userId = localStorage.getItem('userId');
            const token = localStorage.getItem('accessToken');
            let contributionsData = null;
            let processData = null;
            let attributersData = null; // Define it here to make sure it's available for all conditions
    
            if (userId === '2' || userId === '3' || userId === '4') {
                // Use the contributions endpoint if userId is exactly 2, 3, or 4
                const contributionsResponse = await fetch(
                    `${apiUrl}api/contributions/?user_boss=${userId}&status=acepted`,
                    {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    }
                );
                if (!contributionsResponse.ok) throw new Error("Failed to fetch contributions data.");
                contributionsData = await contributionsResponse.json();
    
                // Extract the initials from the full names of user_refer
                const userReferInitials = contributionsData.map(contribution => {
                    const fullName = contribution.user_refer.full_name;
                    const nameParts = fullName.split(' ');  // Split the name by space (first name, last name, etc.)
                    const initials = nameParts.map(part => part.charAt(0).toUpperCase()).join('');  // Get the first letter of each part
                    return initials;
                });
    
                // Return the initials to display in circles
                return userReferInitials.slice(0, 5);  // Only take the first 5 initials
                
            } else {
                // Default behavior for other userIds
                const tailResponse = await fetch(`${apiUrl}api/tail/?user=${userId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (!tailResponse.ok) throw new Error("Failed to fetch tail data.");
                const tailData = await tailResponse.json();
    
                const nextCategory = tailData[0].category_display.id + 1;
    
                const attributersResponse = await fetch(
                    `${apiUrl}api/atributors/?category_display=${nextCategory - 1}&user_display=${userId}`,
                    {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    }
                );
                if (!attributersResponse.ok) throw new Error("Failed to fetch attributers data.");
                attributersData = await attributersResponse.json();
    
                if (!attributersData.length) {
                    throw new Error("Attributers data is empty.");
                }
                const attributersId = attributersData[0].id;
    
                const chosenResponse = await fetch(
                    `${apiUrl}api/chosen/?category_display=${nextCategory - 1}&user_display=${userId}`,
                    {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    }
                );
                if (!chosenResponse.ok) throw new Error("Failed to fetch chosen data.");
                const chosenData = await chosenResponse.json();
    
                if (!chosenData.length) {
                    throw new Error("Chosen data is empty.");
                }
                const chosenId = chosenData[0].id;
    
                const processResponse = await fetch(
                    `${apiUrl}api/process/?atributors_id=${attributersId}&chosen_id=${chosenId}`,
                    {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    }
                );
                if (!processResponse.ok) throw new Error("Failed to fetch process data.");
                processData = await processResponse.json();
            }
    
            // Now handle the processData and attributersData for full names
            if (processData && attributersData) {
                const result = Object.entries(processData[0])
                    .filter(([key, value]) => value && value.id === Number(userId)) // Filter where id is userId and value is not null
                    .map(([key, value]) => key.replace(/_(\d+)$/, '$1')); // Get the tail_pos keys
    
                const fullNames = result.map(key => {
                    // Find the entry corresponding to the key in attributersData
                    const positionData = attributersData[0][key];
                        
                    // If there's data for that key and it has user_display, return the full_name
                    if (positionData && positionData.user_display) {
                        return positionData.user_display.full_name;
                    } else {
                        return null; // If no data is found, return null or handle as needed
                    }
                });
    
                const initials = result
                    .map(key => {
                        // Find the entry corresponding to the key in attributersData
                        const positionData = attributersData[0][key];
                            
                        // If there's data for that key and it has user_display, return the initials of full_name
                        if (positionData && positionData.user_display) {
                            const fullName = positionData.user_display.full_name;
                            return fullName
                                .split(' ') // Split the full name into parts
                                .map(part => part.charAt(0).toUpperCase()) // Take the first letter of each part
                                .join(''); // Join the initials together
                        } else {
                            return null; // If no data is found, return null
                        }
                    })
                    .filter(initial => initial !== null); // Remove null values
                    
                return initials;
            } else {
                return [];  // Return empty array if no processData or attributersData
            }
    
        } catch (error) {
            return [];
        }
    };
    
    const fetchReceivedContributions = (userId,token) => {

        fetch(`${apiUrl}api/contributions/?user_boss=${userId}&status=acepted`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setReceivedContributions(data.length); // Update the length in state
            })
    };
    
    const fetchUserPosition = (userId, token) => {
        fetch(`${apiUrl}api/tail/?user=${userId}&status=pending`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.json())
        .then(data => {
            // Assuming `data` is a list, find the index of the user
            const position = data.findIndex(item => item.user_display.id === parseInt(userId)) + 1; // +1 for 1-based position
            setPosFound(position);
        })
    };
    
    return (
        <div className="home-dash-container">
            <Homebarpage />
            <BGContainer />
            <div className="content">
                <div className="header">
                    <h1>Hola, {user ? user.full_name : 'Cargando...'} 👋</h1>
                    <h1>Posición actual: {posFound || 'Estas fuera de la cola'}</h1>
                    <button className="new-contribution-button" onClick={handleModalOpen}>
                        + Nuevo Aporte
                    </button>
                </div>
                <div className="section">
                    <h2>Aportes recibidos</h2>
                    <div className="circles-container">
                        <div className="big-circle">
                            {user && user.full_name
                                ? `${user.full_name.split(" ")[0][0]}${user.full_name.split(" ")[1][0]}`
                                : 'Cargando...'}
                        </div>
                        <div className="small-circles">
                            {circleContent.slice(0, 5).map((name, index) => (
                                <div key={index} className="small-circle">
                                    {name || ""}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="details">
                        <h4>{user ? user.full_name : 'Cargando...'}</h4>
                        <h5>Aportes Recibidos: {receivedContributions}</h5>
                    </div>
                </div>

                <CategoriesSection categoriesData={categoriesData} />

                {/* Referral Section */}
                <div className="section referral-section">
                    <h2>Tu Enlace de Referido</h2>
                    <p>Comparte este enlace con tus amigos para poder avanzar de la categoria 3</p>
                    <div className="referral-link-container">
                        <input
                            type="text"
                            readOnly
                            value={referralLink}
                            className="referral-link-input"
                        />
                        <button onClick={copyReferralLink} className="copy-link-button">
                            Copiar Enlace
                        </button>
                    </div>
                </div>

                {isModalVisible && (
                    <div className="modal-overlay">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>Crear un Aporte</h4>
                                <button className="close-button" onClick={handleModalClose}>X</button>
                            </div>
                            <div className="modal-body">
                                {attributorInfo && (
                                    <>
                                        <h6>Destinatario:</h6>
                                        <h6>{attributorInfo.email}</h6>
                                        <h6>Nombre Completo:</h6>
                                        <h6>{attributorInfo.full_name}</h6>
                                        <h6>Número de contacto:</h6>
                                        <h6>{attributorInfo.cellphone}</h6>
                                        <h6>Tipo de cuenta:</h6>
                                        <h6>{attributorInfo.type_account.charAt(0).toUpperCase() + attributorInfo.type_account.slice(1)}</h6>
                                        <h6>Número de cuenta:</h6>
                                        <h6>{attributorInfo.num_account}</h6>
                                    </>
                                )}

                                {categoryInfo && valueInfo && (
                                            <>
                                                <h6>Categoría:</h6>
                                                <h6>{categoryInfo}</h6>
                                                <h6>Valor:</h6>
                                                <h6>{valueInfo}</h6>
                                            </>
                                )}

                                <button className="create-contribution-button" onClick={handleCreateContribution}>Crear Aporte</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <HomeFooter />
        </div>
    );
}

export default HomeDash;


