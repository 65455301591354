// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createBrowserRouter, RouterProvider, Route } from 'react-router-dom';
import AboutUs from './pages/AboutUs';
import Login from './pages/Login';
import Register from './pages/Register';
import HomeDash from './pages/HomeDash';
import ProtectedRoute from './ProtectedRoute'; // Import the ProtectedRoute component

const router = createBrowserRouter([
  {
    path: "home",
    element: <App/> 
  },
  {
    path: "/",
    element: <App/> 
  },
  {
    path: "about",
    element: <AboutUs/> 
  },
  {
    path: "login",
    element: <Login/> 
  },
  {
    path: "register",
    element: <Register/>
  },
  {
    path: "home-dash",
    element: (
      <ProtectedRoute>
        <HomeDash/>
      </ProtectedRoute>
    ) // Wrap HomeDash in ProtectedRoute
  }
]); 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
