import React, { useState } from "react";
import Navbarpage from "../Components/Navbarpage";
import Footerpage from "../Components/Footerpage";
import 'aos/dist/aos.css';
import '../Styles/Login.css'
import logo from '../Media/logo.png';
import BG_logo from '../Media/login_BG.jpeg';
import { useNavigate } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL_LOCAL;

function Login() {
    const [formValues, setFormValues] = useState({
        email: '',
        password: '',
    });

    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    // Validate form inputs
    const validate = () => {
        const errors = {};
        if (!formValues.email) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
            errors.email = 'Email is invalid';
        }
        if (!formValues.password) {
            errors.password = 'Password is required';
        }
        return errors;
    };

    // Handle form submission and login process
    const handleSubmit = (e) => {
        e.preventDefault();

        const validationErrors = validate();

        console.log(`${apiUrl}login/`);

        if (Object.keys(validationErrors).length === 0) {
            // Make the POST request to the new login endpoint
            fetch(`${apiUrl}login/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: formValues.email,
                    password: formValues.password,
                }),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.access) {
                        // Login successful - store JWT tokens and user ID
                        localStorage.setItem('accessToken', data.access);
                        localStorage.setItem('refreshToken', data.refresh);
                        localStorage.setItem('userId', data.user_id); // Store user ID

                        // Navigate to the dashboard or home page
                        navigate('/home-dash');
                    } else if (data.non_field_errors) {
                        // Handle login failure
                        alert('Email o contraseña no válidos');
                    }
                })
                .catch(error => {
                    alert('There was an error with the login. Please try again.');
                });
        } else {
            setErrors(validationErrors);
        }
    };

    const handleChange = (e) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <div>
            <link href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap" rel="stylesheet"></link>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css" integrity="sha512-RX88ySCBE8szC08xHbq8QA4B0Em+TjvDq78OV94ImkmCbDw+LCTCHLmuPKAGtJ8pGy14G9+d7rjYfocNN5XtIg==" crossorigin="anonymous" referrerpolicy="no-referrer" />
            <Navbarpage />
            <div className="main-div">
                <header className='Login-header'></header>
                <div className="login-cont">
                    <img src={logo} alt="Logo" className="logo-login" />
                    <h2 className="brand-title">Cafecito</h2>
                    <br />
                    <h4 className="login-title">Iniciar Sesión</h4>
                    <p className="go-register">¿No tienes una cuenta?</p>
                    <a href="register" className="registrarse">Comienza a sembrar ahora</a>
                    <form className="login-form" onSubmit={handleSubmit}>
                        <label htmlFor="user-email">Correo electrónico</label>
                        <input
                            type="email"
                            id="user-email"
                            name="email"
                            value={formValues.email}
                            onChange={handleChange}
                        />
                        {errors.email && <span>{errors.email}</span>}
                        <label htmlFor="pass-email">Contraseña</label>
                        <input
                            type="password"
                            id="pass-email"
                            name="password"
                            value={formValues.password}
                            onChange={handleChange}
                        />
                        {errors.password && <span>{errors.password}</span>}
                        <button type="submit">Ingresar</button>
                    </form>
                    <br /><br /><br /><br /><br /><br />
                </div>
                <div className="img-container">
                    <img src={BG_logo} alt="BG" className="BG-login"></img>
                </div>
            </div>
            <Footerpage />
        </div>
    );
}

export default Login;
