import image_1 from './Media/Cafecito-think.png';
import image_2 from './Media/grupo-cafecito.jpeg';
import image_3 from './Media/mister.jpeg';
import image_4 from './Media/female.jpeg';
import image_5 from './Media/mister2.jpeg';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect } from "react";
import Navbarpage from './Components/Navbarpage';
import Footerpage from './Components/Footerpage';
require('dotenv').config();

function App() {
  useEffect(
    () => {
      AOS.init({ duration: 2000 })
    }, []
  )
  return (
    <div className="App">
      <Navbarpage />
      <header className='App-header'>
        <main className="main-content">
          <h1 className='Main-header' data-aos="fade-up">Explora el Mundo de Cafecito </h1>
          <div className='first-section' data-aos="fade-up">
            <div className='left-side'>
              <img src={image_1} alt="Circle" className="circle-image" />
            </div>
            <div className='rigth-side'>
              <p className='first-p'>Cafecito Global, es un sistema donde un gesto de ayuda se multiplica, 
                creando un ciclo virtuoso donde todos contribuyen y todos se benefician, en un mismo sitio. 
                Únete y se parte de esta comunidad. Descubre cómo juntos podemos sembrar un futuro más próspero y solidario.
              </p>
            </div>
          </div>
          <div className='second-section' data-aos="fade-up">
            <div className="content">
              <img src={image_2} alt="Rectangle" className="rectangle-image" />
              <p className='second-p'>Invita a tus amigos y compañeros a que te den un cafecito, a que hagan parte de nuestro equipo.
                Completa hasta 7 niveles, a medida que vas recolectando cafecitos vas subiendo de nivel.
              </p>
            </div>
          </div>
          <div className='instructions-section'>
              <h1 className='title-ints' data-aos="fade-up">Consejos importantes para ser parte de cafecito</h1>
              <br></br><br></br>
              <div className='steps'>
                  <div className='step' data-aos="fade-up">
                      <h4>#1</h4>
                      <p>Para entrar en la cola global debes tener $2 USD que te pide el nivel 1. 
                        El cual lo recibe quien estaba antes registrado, debes comunicarte con esa 
                        persona para confirmarle que ya le vas a enviar el aporte correspondiente, 
                        te pueda aceptar en el sistema y quedes enlistado en la cola para que tengas 
                        tu turno, si no estás confirmado por la persona a quien corresponde, 
                        iras perdiendo el turno, ya que la cola es única e irán avanzando los nuevos detrás de ti.</p>
                  </div>
                  <div className='step' data-aos="fade-up">
                    <h4>#2</h4>
                    <p>Recibes un X5 en aportes a medida que van ingresando a la cola. 
                      Estos aportes no son continuos, sino alternados: en un sistema 1x1, 
                      uno es para ti, otro no, el siguiente sí, el otro no. 
                      Y así sucesivamente hasta que se reciban los aportes correspondientes 
                      para cerrar el nivel. Tendrás que confirmar cada aporte, en tu panel dentro de 
                      la plataforma, se mostrará el avance de los aportes recibidos hasta completar los 5 únicos. 
                      Luego, este nivel se cerrará para que desbloquees el siguiente, donde de tus ganancias, 
                      realizarás un nuevo aporte que corresponda al nivel superior. Este proceso continúa hasta 
                      el nivel 7, que es el máximo disponible hasta el momento.</p>
                  </div>
                  <div className='step' data-aos="fade-up">
                    <h4>#3</h4>
                    <p>NO SE PERMITEN MULTICUENTAS: Solo se permite una cuenta por persona. 
                      Si rompes esta regla, serás ELIMINADO por IP y CORREO. Sin aviso, ya que 
                      al aceptar los términos y condiciones, nos das la autorización. En caso de 
                      que incumplas esta regla de eliminarte del proyecto sin aviso alguno, 
                      el sistema detecta esta infracción automáticamente por parte de algún afiliado.</p>
                  </div>
                  <div className='step' data-aos="fade-up">
                    <h4>#4</h4>
                    <p>ATENCIÓN: Del nivel 1 al 3 puedes trabajarlos repetidamente si no quieres presentarle 
                      a personas nuevas el proyecto. Del nivel 4 en adelante te pedirá unos pasos para desbloquear 
                      esos niveles superiores, únicamente si quieres escalar a recibir más aportes y si tienes 
                      la visión de expandirte y ayudar a más personas, más no es obligatorio.</p>
                  </div>
              </div>
          </div>
          <div className='third-section' data-aos="fade-up">
            <h1 className='reviews'>Reviews</h1>
            <div className='reviews-section'>
              <div className='review'>

                <img src={image_3} alt="Male" className="male-image" />

                <div className="rating">
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star"></span>
                </div>
                <p className='description'>Descubrir esta página ha sido una revelación para mí.
                  Como joven profesional, a menudo me sentía impotente ante los problemas del mundo,
                  pero aquí encontré un espacio donde cada gesto de ayuda se multiplica exponencialmente.
                  Es inspirador ver cómo juntos podemos marcar la diferencia, sembrando un futuro más solidario y próspero.
                  Me siento parte de algo más grande que yo mismo y estoy emocionado de contribuir y beneficiarme de este
                  ciclo virtuoso.</p>
              </div>
              <div className='review'>
                <img src={image_4} alt="Female" className="female-image" />
                <div className="rating">
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star"></span>
                </div>
                <p className='description'>Unirme a esta comunidad ha sido una experiencia transformadora.
                  Siempre he creído en el poder de la colaboración, pero aquí he visto cómo ese poder se amplifica de
                  manera increíble. Cada acto de ayuda, por pequeño que sea, se convierte en una semilla
                  que florece en beneficios para todos. Es increíble ser parte de un movimiento donde todos
                  contribuyen y todos se benefician. Juntos estamos sembrando un futuro más próspero y solidario,
                  y me llena de esperanza ver el impacto que estamos logrando.</p>
              </div>
              <div className='review'>
                <img src={image_5} alt="Male-2" className="male2-image" />
                <div className="rating">
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star"></span>
                </div>
                <p className='description'>Como alguien que ha visto muchas cosas a lo largo de los años, puedo decir
                  con confianza que esta página es una luz en medio de la oscuridad. El espíritu de solidaridad y colaboración
                  que se vive aquí es verdaderamente inspirador. Ver cómo las acciones positivas se multiplican y
                  generan un ciclo virtuoso de ayuda mutua me llena de gratitud y esperanza en la humanidad.
                  Unirse a esta comunidad no solo es una oportunidad para contribuir, sino también para beneficiarse
                  de la generosidad y el apoyo de otros. Estoy agradecido de ser parte de este movimiento hacia un futuro
                  más próspero y solidario para todos.</p>
              </div>
            </div>
          </div>
        </main>
      </header>
      <Footerpage />
    </div>
  );
}

export default App;
