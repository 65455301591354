import React, { useEffect } from "react";
import BG_Dash from '../Media/BG_Dash.jpeg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../Styles/BGContainer.css';

function BGContainer() {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <div className="bg-container" data-aos="fade-down">
            <img src={BG_Dash} alt="bg-image" className="BG_img" />
            <div className="rectangle">
                <div className="tables-container-2">
                    <div className="left-side-dash">
                        <h1>CAFECITO GLOBAL</h1>
                        <h4>Economía de aportes mundial</h4>
                    </div>
                    <div className="right-side-dash">
                        <hr />
                        <p>Soy líder y guía de mi equipo</p>
                        <hr />
                        <p>Mejoré mi calidad de vida</p>
                        <hr />
                        <p>Hoy lograré mis metas</p>
                        <hr />
                        <p>El trabajo de hoy es el éxito de mañana</p>
                        <hr />
                        <p>Recordar todos los días...</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BGContainer;