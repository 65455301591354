import React, { useEffect, useState } from "react";
import logo from '../Media/coffee.png';
import bell from '../Media/bell.png';
import bell_dot from '../Media/bell-dot.png';
import profilePic from '../Media/profile-pic.png';
import '../Styles/Homebarpage.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function Homebarpage() {
    const navigate = useNavigate(); // Initialize navigate
    const [hasNotifications, setHasNotifications] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isNotificationCardVisible, setIsNotificationCardVisible] = useState(false);
    const [userData, setUserData] = useState(null); // State to store user data
    const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility
    const [isEditable, setIsEditable] = useState(false);
    const [selectedNotificationId, setSelectedNotificationId] = useState(null);
    const [isDismissModalVisible, setIsDismissModalVisible] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL_LOCAL;

    useEffect(() => {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('accessToken'); // Get the stored JWT token

        // Fetch user details based on userId
        if (userId && token) {
            fetch(`${apiUrl}api/users/?id=${userId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`, // Attach the token in the Authorization header
                    'Content-Type': 'application/json'
                }
            })
                .then(response => response.ok ? response.json() : Promise.reject('Failed to fetch user data'))
                .then(data => {
                    if (data && data.length > 0) {
                        setUserData(data[0]); // Set user data if found
                    }
                })
        } else {
        }

        // Fetch notifications
        if (userId && token) {
            fetch(`${apiUrl}api/user_notifications/?user=${userId}&status=false`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`, // Attach the token in the Authorization header
                    'Content-Type': 'application/json'
                }
            })
                .then(response => response.ok ? response.json() : Promise.reject('Failed to fetch notifications'))
                .then(data => {
                    setHasNotifications(data && data.length > 0);
                    setNotifications(data); // Store notifications data
                })
        }
    }, []);

    function toggleDropdown() {
        setIsDropdownVisible(prevState => !prevState);
    }

    function toggleNotificationCard() {
        setIsNotificationCardVisible(prevState => !prevState);
    }

    // Open the edit modal
    const handleEditClick = () => {
        setIsModalVisible(true);
    };

    // Close the edit modal
    const handleModalClose = () => {
        setIsModalVisible(false);
    };

    // Logout function
    const handleLogout = () => {
        localStorage.removeItem('accessToken'); // Remove access token
        localStorage.removeItem('userId'); // Remove user ID
        navigate('/'); // Redirect to homepage
    };

    // Function to handle saving the data
    // Function to handle the save action
    const handleSave = async () => {
        const userId = localStorage.getItem('userId'); // Get the user ID from localStorage
        const token = localStorage.getItem('accessToken'); // Get the JWT token
    
        // Prepare the data object for the PUT request
        const updatedUserData = {
            email: userData.email,            // Ensure valid email format
            full_name: userData.full_name,
            cellphone: userData.cellphone,     // Ensure it's a string
            indicative: userData.indicative,    // Ensure it's a valid number
            num_account: userData.num_account, // Ensure it's a valid number
            type_account: userData.type_account // Ensure it's one of the choices
        };
    
        // Update the user data
        try {
            const userResponse = await fetch(`${apiUrl}api/users/${userId}/`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`, // Include JWT token
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updatedUserData), // Send the updated user data as JSON
            });
    
            if (!userResponse.ok) {
                const error = await userResponse.json();
                throw error;
            }
    
            const updatedUserDataResponse = await userResponse.json();
            setUserData(updatedUserDataResponse);
            setIsModalVisible(false);
            
            // Fetch user notifications for this user where status is false
            const notificationsResponse = await fetch(`${apiUrl}api/user_notifications/?user=${userId}&status=false`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            });
    
            if (!notificationsResponse.ok) {
                const error = await notificationsResponse.json();
                throw error;
            }
    
        } catch (error) {
        }
    };

    const handleCheckNotification = async (notificationId) => {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('accessToken');
    
        try {

            const notificationResponse = await fetch(`${apiUrl}api/user_notifications/${notificationId}/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
    
            if (!notificationResponse.ok) {
                throw new Error("Failed to fetch notification details.");
            }
    
            const notificationData = await notificationResponse.json();

            const tailResponse = await fetch(`${apiUrl}api/tail/?user=${notificationData.destinatary}&status=pending`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
    
            if (!tailResponse.ok) {
                throw new Error("Failed to fetch tail details.");
            }
    
            const tailData = await tailResponse.json();
            const categoryBoard = tailData[0].category_display.category_board;
            const tailId =  tailData[0].id;
            const categoryNumber = categoryBoard.replace("category_", "");


            const contributionResponse = await fetch(`${apiUrl}api/contributions/?user_boss=${userId}&user_refer=${notificationData.destinatary}&level_category=${categoryNumber}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
    
            if (!contributionResponse.ok) {
                throw new Error("Failed to fetch contribution details.");
            }
    
            const contributionData = await contributionResponse.json();
            const contributionId = contributionData[0].id;

            const contributionUpdateResponse = await fetch(`${apiUrl}api/contributions/${contributionId}/`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ status: "acepted" })
            });
    
            if (!contributionUpdateResponse.ok) {
                throw new Error("Failed to update contribution status.");
            }

            const updateTailResponse = await fetch(`${apiUrl}api/tail/${tailId}/`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,  // Include the authorization token if needed
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user: notificationData.destinatary,
                    status: 'finish', // The status to set
                    category: categoryNumber,
                }),
            });

            const responseData = await updateTailResponse.json();

            // Prepare the body with all necessary fields
            const requestBody = {
                user: userId,                   // Include user ID
                notification_chose: 1, // Include notification choice ID
                status: true                                   // Mark as read
            };
    
            // Update the notification with PUT request
            const updateResponse = await fetch(`${apiUrl}api/user_notifications/${notificationId}/`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });
    
            if (!updateResponse.ok) {
                throw new Error("Failed to update notification status.");
            }


            const createNotificationResponse = await fetch(`${apiUrl}api/user_notifications/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,  // Include the authorization token if needed
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user: notificationData.destinatary, // The user sending the notification
                    notification_chose: 4, // The notification type/choice (replace 4 with the correct value)
                    status: false, // The status of the notification
                    destinatary: userId, // The actual recipient of the notification
                }),
            });
        
            const createResponseData = await createNotificationResponse.json();
    
            // Remove the updated notification from the list
            setNotifications((prevNotifications) =>
                prevNotifications.filter((notification) => notification.id !== notificationId)
            );
    
            // Update hasNotifications if there are still unread notifications
            setHasNotifications(notifications.length > 1);
    
        } catch (error) {
        }
    };
    
    
    const handleDismissNotification = (notificationId) => {
        setSelectedNotificationId(notificationId);
        setIsDismissModalVisible(true); // Show confirmation modal
    };

    const confirmDismissNotification = () => {
        setNotifications((prevNotifications) =>
            prevNotifications.filter((notification) => notification.id !== selectedNotificationId)
        );
        setHasNotifications(notifications.length > 1); // Update the hasNotifications status
        setIsModalVisible(false); // Hide the modal after confirming
    };

    const cancelDismissNotification = () => {
        setIsDismissModalVisible(false);
    };

    const handleMarkAsRead = async () => {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('accessToken');
    
        try {
            // Step 1: Perform GET request to retrieve notification
            const getResponse = await fetch(`${apiUrl}api/user_notifications/?user=${userId}&notification_chose=4&status=false`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
    
            if (!getResponse.ok) {
                return;
            }
    
            const notifications = await getResponse.json();
    
            // Ensure there's a notification to update
            if (!notifications.length) {
                return;
            }
    
            const notificationId = notifications[0].id; // Get the first matching notification's ID
    
            // Step 2: Perform PUT request to update notification status
            const putResponse = await fetch(`${apiUrl}api/user_notifications/${notificationId}/`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user: userId,
                    notification_chose: 4, // Change notification type as required
                    status: true, // Mark as read
                }),
            });
    
            if (!putResponse.ok) {
                return;
            }
            window.location.reload()
    
        } catch (error) {
        }
    };
    
    

    return (
        <nav className="navbar">
            <div className="left-section">
                <a href='home-dash'>
                    <img src={logo} alt='logo-home' className='logo-home' />
                </a>
                <a href="home-dash" className='brand-name'>Cafecito</a>
            </div>
            <div className="right-section">
                <a href='#' onClick={toggleNotificationCard}>
                    <img src={hasNotifications ? bell_dot : bell} alt='notification bell' className='notification-bell' />
                </a>
                <div className="info-section">
                    {/* Display user info dynamically */}
                    {userData ? (
                        <>
                            <h3>{userData.full_name}</h3>
                            <h5>Información General</h5>
                        </>
                    ) : (
                        <p>Loading user data...</p>
                    )}
                </div>
                <img 
                    src={profilePic} 
                    alt="profile" 
                    className="profile-pic" 
                    onClick={toggleDropdown} 
                />
                {isDropdownVisible && userData && (
                    <div className="profile-dropdown">
                        <h3>{userData.full_name}</h3>
                        <h5>({userData.indicative}) {userData.cellphone}</h5>
                        <h5>{userData.email}</h5>
                        <h5>{userData.type_account}</h5>
                        <button className="edit-button" onClick={handleEditClick}>Editar Información</button>
                        {/* Logout button */}
                        <button className="logout-button" onClick={handleLogout}>Cerrar Sesión</button>
                    </div>
                )}
                {isNotificationCardVisible && hasNotifications && (
                    <div className="notification-card">
                        <h5>Notificaciones</h5>
                        {notifications.length > 0 ? (
                            notifications.map((notification, index) => {
                                // Log notification to inspect its structure
                                return (
                                    <div key={index} className="notification-item">
                                        {/* Check if notification_chose exists and display its details */}
                                        <h5>{notification.notification_chose?.type_notification || 'Unknown Notification Type'}</h5>
                                        <p>{notification.notification_chose?.context || 'No additional context available'}</p>

                                        {/* Conditionally render the check and X buttons if notification_chose.id is 1 */}
                                        {notification.notification_chose?.id === 1 && (
                                            <div className="notification-actions">
                                                <button className="check-notification" onClick={() => handleCheckNotification(notification.id)}>✔️</button>
                                                <button className="cancel-notification" onClick={() => handleDismissNotification(notification.id)}>❌</button>
                                            </div>
                                        )}
                                        {notification.notification_chose?.id === 4 && (
                                            <div className="notification-actions">
                                                <button className="mark-as-read" onClick={() => handleMarkAsRead()}>
                                                    Marcar como leído
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                );
                            })
                        ) : (
                            <p>No hay nuevas notificaciones</p>
                        )}
                    </div>
                )}

                {/* Confirmation Modal */}
                {isDismissModalVisible && (
                    <div className="modal-dimiss-overlay">
                        <div className="modal-dimiss-container">
                            <div className="modal-dimiss-header">
                                <h3>Confirmar Acción</h3>
                                <button className="close-btn-dimiss" onClick={cancelDismissNotification}>
                                    &times;
                                </button>
                            </div>
                            <div className="modal-dimiss-body">
                                <p>¿Estás seguro que no has recibido este aporte? Cancelarlo afectará a otro usuario.</p>
                            </div>
                            <div className="modal-dimiss-footer">
                                <button
                                    className="cancel-btn-dimiss"
                                    onClick={cancelDismissNotification}
                                >
                                    Cancelar
                                </button>
                                <button
                                    className="confirm-btn-dimiss"
                                    onClick={confirmDismissNotification}
                                >
                                    Confirmar
                                </button>
                            </div>
                        </div>
                    </div>
                )}


            </div>

            {/* Modal window */}
            {isModalVisible && (
            <div className="modal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>Editar Información</h2>
                        <button className="edit-button-modal" onClick={() => setIsEditable((prev) => !prev)}>
                            ✏️
                        </button>
                    </div>
                    {/* Two-column layout for user information */}
                    <div className="form-grid">
                        <div className="form-item">
                            <label>Nombre: </label>
                            {isEditable ? (
                                <input
                                    className="wide-input"
                                    type="text"
                                    value={userData.full_name}
                                    onChange={(e) => setUserData({ ...userData, full_name: e.target.value })}
                                />
                            ) : (
                                <span className="non-editable-text">{userData.full_name}</span>
                            )}
                        </div>
                        <div className="form-item">
                            <label>Indicativo: </label>
                            {isEditable ? (
                                <input
                                    className="wide-input"
                                    type="text"
                                    value={userData.indicative}
                                    onChange={(e) => setUserData({ ...userData, indicative: e.target.value })}
                                />
                            ) : (
                                <span className="non-editable-text">{userData.indicative}</span>
                            )}
                        </div>
                        <div className="form-item">
                            <label>Teléfono: </label>
                            {isEditable ? (
                                <input
                                    className="wide-input"
                                    type="text"
                                    value={userData.cellphone}
                                    onChange={(e) => setUserData({ ...userData, cellphone: e.target.value })}
                                />
                            ) : (
                                <span className="non-editable-text">{userData.cellphone}</span>
                            )}
                        </div>
                        <div className="form-item">
                            <label>Email: </label>
                            {isEditable ? (
                                <input
                                    className="wide-input"
                                    type="email"
                                    value={userData.email}
                                    onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                                />
                            ) : (
                                <span className="non-editable-text">{userData.email}</span>
                            )}
                        </div>
                        <div className="form-item">
                            <label>Tipo de cuenta: </label>
                            {isEditable ? (
                                <select
                                    className="wide-input"
                                    value={userData.type_account}
                                    onChange={(e) => setUserData({ ...userData, type_account: e.target.value })}
                                >
                                    <option value="nequi">Nequi</option>
                                    <option value="daviplata">Daviplata</option>
                                    <option value="binance">Binance</option>
                                    <option value="bancolombia">Bancolombia</option>
                                    <option value="davivienda">Davivienda</option>
                                    <option value="bbva">BBVA</option>
                                    <option value="cajasocial">CajaSocial</option>
                                    <option value="itau">Itau</option>
                                </select>
                            ) : (
                                <span className="non-editable-text">{userData.type_account.charAt(0).toUpperCase() + userData.type_account.slice(1)}</span>
                            )}
                        </div>
                        <div className="form-item">
                            <label>Número de cuenta: </label>
                            {isEditable ? (
                                <input
                                    className="wide-input"
                                    type="text"
                                    value={userData.num_account}
                                    onChange={(e) => setUserData({ ...userData, num_account: e.target.value })}
                                />
                            ) : (
                                <span className="non-editable-text">{userData.num_account}</span>
                            )}
                        </div>
                    </div>
                    {/* Save button */}
                    <button className="save-button" onClick={handleSave}>Guardar</button>
                    {/* Close modal button */}
                    <button className="close-modal" onClick={handleModalClose}>Cerrar</button>
                </div>
            </div>
        )}





        </nav>
    );
}

export default Homebarpage;
