import React, { useEffect } from "react";
import origen from '../Media/origen.jpeg';
import mision from '../Media/mision.jpeg';
import vision from '../Media/vision.jpeg';
import proyeccion from '../Media/proyeccion.jpeg';
import historia from '../Media/historia.jpeg';
import '../Styles/AboutUs.css';
import Navbarpage from "../Components/Navbarpage";
import Footerpage from "../Components/Footerpage";
import AOS from 'aos';
import 'aos/dist/aos.css';

function AboutUs() {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <div>
            <link href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap" rel="stylesheet"></link>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css" integrity="sha512-RX88ySCBE8szC08xHbq8QA4B0Em+TjvDq78OV94ImkmCbDw+LCTCHLmuPKAGtJ8pGy14G9+d7rjYfocNN5XtIg==" crossorigin="anonymous" referrerpolicy="no-referrer" />
            <Navbarpage />
            <div>
                <header className='AboutUs-header'>
                    <div class="container">
                        <div class="text-content">
                            <h1 className="title-1" data-aos="fade-up">Nuestra Historia</h1>
                            <p className="p_1" data-aos="fade-up">
                            Cafecito empezó como una idea en el año 2023. Momento en que nos dimos cuenta de que la economía estaba en un punto crítico. En familias, empresas y emprendimientos globalmente. 
                            Afectando muchos bolsillos que no tenían un plan B, ni soporte para un golpe de estos.
                            <br></br><br></br>
                            Entrando en acción, decidimos crear este proyecto que bautizamos como economía de aportes. Algo nuevo, diferente e importante, que fuera sustentable en el tiempo. 
                            ¿Cómo lo logramos? Claramente, trabajando en equipo, nos dimos cuenta de que la solución está en brindarnos la mano. Unos a otros, beneficiándonos mano a mano, un corre, corre de voz a voz, nos llevó a tomar la decisión.
                            <br></br><br></br>
                            En 2024, un día del mes de febrero, empezamos con la frase (Regalame un cafecito) Y logramos esto que estás viendo ahora. El proyecto final de cafecito global para ti, para mí, para todos.
                            <br></br><br></br>
                            Bienvenido a Cafecito.</p>
                        </div>
                        <div class="image-content">
                            <img src={historia} alt="Placeholder"  className="circle-about" data-aos="fade-up"/>
                        </div>
                    </div>
                    <div class="container">
                        <div class="image-content">
                            <img src={origen} alt="Placeholder"  className="circle-about" data-aos="fade-up"/>
                        </div>
                        <div class="text-content">
                            <h1 className="title-1" data-aos="fade-up">Origen y Destino</h1>
                            <p className="p_1" data-aos="fade-up">Cafecito es fundado y creado principalmente en Colombia. Queremos llegar a donde tu decides llevar el proyecto, 
                                ya sea, en tu país o en los paises vecinos.
                                <br></br><br></br>
                                Es un proyecto global que quiere contribuir en la vida financiera de personas que deciden poner su grano, no solo de cafecito,
                                si no tambien, demostrarles un mensaje de apoyo a quienes mas lo necesitan.</p>
                        </div>
                    </div>
                    <div class="container">
                        <div class="text-content">
                            <h1 className="title-1" data-aos="fade-up">Misión</h1>
                            <p className="p_1" data-aos="fade-up">En Cafecito, nuestra misión es promover la unidad y el liderazgo compartido dentro de nuestro equipo.
                            <br></br><br></br>
                                Buscamos construir un ambiente donde la cohesión y la colaboración sean el motor que impulse nuestro crecimiento económico y personal. 
                                Trabajamos juntos hacia un objetivo común, apoyándonos mutuamente para alcanzar nuestras metas individuales y colectivas</p>
                        </div>
                        <div class="image-content">
                            <img src={mision} alt="Placeholder"  className="circle-about" data-aos="fade-up"/>
                        </div>
                    </div>
                    <div class="container">
                        <div class="image-content">
                            <img src={vision} alt="Placeholder"  className="circle-about" data-aos="fade-up"/>
                        </div>
                        <div class="text-content">
                            <h1 className="title-1" data-aos="fade-up">Visión</h1>
                            <p className="p_1" data-aos="fade-up">Nuestra visión en Cafecito es crear un entorno donde la colaboración y el crecimiento mutuo sean los pilares fundamentales. 
                            Nos esforzamos por cultivar un equipo cohesionado donde cada individuo tenga la oportunidad de florecer no solo a nivel personal, 
                            sino también como parte de un colectivo comprometido con un propósito compartido.</p>
                        </div>
                    </div>
                    <div class="container">
                        <div class="text-content">
                            <h1 className="title-1" data-aos="fade-up">Proyección</h1>
                            <p className="p_1" data-aos="fade-up">En Cafecito nos vemos como una comunidad cohesionada, donde cada miembro aporta de manera activa y comprometida hacia el éxito colectivo. 
                            Visualizamos un equipo fuerte y unido, que se apoya mutuamente en el camino hacia un desarrollo personal y económico sostenible. Nuestra proyección incluye un 
                            crecimiento continuo basado en valores de colaboración, liderazgo compartido y solidaridad.</p>
                        </div>
                        <div class="image-content">
                            <img src={proyeccion} alt="Placeholder"  className="circle-about" data-aos="fade-up"/>
                        </div>
                    </div>
                </header>
            </div>
            <Footerpage />
        </div>

    )
}

export default AboutUs