import React from 'react';
import '../Styles/HomeFooter.css';

function HomeFooter() {

    return (
        <footer className="footer-home">
            <div className="footer-section">
                <p className='copy-rigth'>Copyrigth © 2024 Cafecito</p>
            </div>
        </footer>
    )

 }

 export default HomeFooter