// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('accessToken'); // Check for access token

    if (!token) {
        alert('Necesitas ingresar primero'); // Show alert if not authenticated
        return <Navigate to="/login" />; // Redirect to the login page
    }

    return children; // Render the protected component if authenticated
};

export default ProtectedRoute;
