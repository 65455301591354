import React from 'react';
import '../Styles/Navbar.css';
import logo from '../Media/coffee.png';

function Navbarpage() {

    function showSidebar() {
        const sidebar = document.querySelector('.sidebar');
        sidebar.style.display = 'flex';
    };

    function hideSidebar() {
        const sidebar = document.querySelector('.sidebar');
        sidebar.style.display = 'none';
    };

    return (
        <nav>
            <ul className="sidebar">
                <li onClick={hideSidebar}><a href="#"><svg xmlns="http://www.w3.org/2000/svg" height="26px" viewBox="0 -960 960 960" width="26px" fill="#eeee"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" /></svg></a></li>
                <li><a href="home" to="home"><img
                    src={logo} // Use your imported logo as the src
                    width="30"
                    height="30"
                    className="logo"
                    alt="Cafecito Logo"
                /></a></li>
                <li><a className='nav-txt' href="home" to="home">Inicio</a></li>
                <li><a className='nav-txt' href="about" to="about">Sobre Nosotros</a></li>
                <li><a className='nav-txt' href="login" to="login">Iniciar Sesión</a></li>
            </ul>
            <ul>
                <li className='logo-container'><a href='home' to='home'><img
                    src={logo} // Use your imported logo as the src
                    width="30"
                    height="30"
                    className="logo"
                    alt="Cafecito Logo"
                /><span className='logo-text'>Cafecito</span></a></li>
                <li className='hide-on-mobile'><a className='nav-txt' href="home" to="home">Inicio</a></li>
                <li className='hide-on-mobile'><a className='nav-txt' href="about" to="about">Sobre Nosotros</a></li>
                <li className='hide-on-mobile'><a className='nav-txt' href="login" to="login">Iniciar Sesión</a></li>
                <li className="menu-btn" onClick={showSidebar}><a href="#"><svg xmlns="http://www.w3.org/2000/svg" height="26px" viewBox="0 -960 960 960" width="26px" fill="#eeee"><path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" /></svg></a></li>
            </ul>
        </nav>
    )
}

export default Navbarpage;
