import React from 'react';
import WA from '../Media/WA.png';
import YT from '../Media/YT.png';
import IG from '../Media/IG.png';
import '../Styles/Footerpage.css';

function Footerpage() {

    return (
        <footer className="footer">
            <div className="footer-links">
            <a href="https://api.whatsapp.com/message/AXGOE3VXBOI6E1?autoload=1&app_absent=0"><img src={WA} alt="WhatsApp" /></a>
            <a href="https://www.youtube.com/@cafecitoglobal"><img src={YT} alt="YouTube" /></a>
            <a href="https://www.instagram.com/cafecitoglobal"><img src={IG} alt="Instagram" /></a>
            </div>
        </footer>
    )

 }

 export default Footerpage