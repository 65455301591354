import '../Styles/Register.css';
import React, { useEffect, useState } from "react";
import Navbarpage from "../Components/Navbarpage";
import Footerpage from "../Components/Footerpage";
import AOS from 'aos';
import 'aos/dist/aos.css';
import logo from '../Media/logo.png';
import BG_logo from '../Media/login_BG.jpeg';
import bcrypt from 'bcryptjs'; // Import bcrypt for hashing
import { useNavigate } from 'react-router-dom';

function Register() {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    const [formValues, setFormValues] = useState({
        fullName: '',
        email: '',
        password: '',
        repeatPassword: '',
        agree: false,
    });

    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormValues({
            ...formValues,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const validate = () => {
        const errors = {};
        if (!formValues.fullName.trim().includes(' ')) {
            errors.fullName = 'Debes ingresar un nombre y apellido valido';
        }
        if (!/\S+@\S+\.\S+/.test(formValues.email)) {
            errors.email = 'Debes ingresar un correo electronico valido';
        }
        if (!formValues.password) {
            errors.password = 'Debes ingresar una contraseña';
        } else if (!/(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(formValues.password)) {
            errors.password = 'La contraseña debe contener al menos una mayúscula, un número y un carácter especial, y tener una longitud mínima de 8 caracteres';
        }
        if (formValues.password !== formValues.repeatPassword) {
            errors.repeatPassword = 'Las contraseñas no coinciden';
        }
        if (!formValues.agree) {
            errors.agree = 'Debes aceptar los términos y condiciones';
        }
        return errors;
    };

    const handleSubmit = (e) => {
        const apiUrl = process.env.REACT_APP_API_URL_LOCAL;
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length === 0) {
            // Hash the password using bcrypt
            const hashedPassword = bcrypt.hashSync(formValues.password, 10);
            
            // Construct the data to be sent in the POST request
            const postData = {
                full_name: formValues.fullName,
                email: formValues.email,
                password: hashedPassword, // Use the hashed password
            };
    
            // Check if referral code exists and append it to the URL
            const referralCode = new URLSearchParams(window.location.search).get('ref'); // Get the ref param from the URL
            const referUrl = referralCode 
                ? `${apiUrl}api/register/?ref=${referralCode}` 
                : `${apiUrl}api/register/`;
    
            // Send the data in a POST request to your backend server
            fetch(referUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData),
            })
            .then(response => {
                const res = response.clone();
                return response.json().then(data => ({ status: res.status, data }));
            })
            .then(({ status, data }) => {
                if (status >= 200 && status < 300) {
                    alert('Usuario registrado con éxito!');
                    navigate('/login');
                } else {
                    // Check for specific error message in the response data
                    if (data.email && data.email.includes("user with this email already exists.")) {
                        alert('Este correo electrónico ya existe.');
                    } else {
                        alert('Hubo un error al momento de registrar el usuario. Por favor, intenta nuevamente más tarde.');
                    }
                }
            })
            .catch(error => {
                // Handle errors if any
                console.error('Error:', error);
                alert('Hubo un error al momento de registrar el usuario. Por favor, intenta nuevamente más tarde.');
            });
    
            // Clear form data
            setFormValues({
                fullName: '',
                email: '',
                password: '',
                repeatPassword: '',
                agree: false,
            });
        } else {
            setErrors(validationErrors);
        }
    };    

    return (
        <div>
            <link href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap" rel="stylesheet"></link>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css" integrity="sha512-RX88ySCBE8szC08xHbq8QA4B0Em+TjvDq78OV94ImkmCbDw+LCTCHLmuPKAGtJ8pGy14G9+d7rjYfocNN5XtIg==" crossorigin="anonymous" referrerpolicy="no-referrer" />
            <Navbarpage />
            <div className="register-div">
                <header className='Register-header'></header>
                <div className="img-container-register">
                    <img src={BG_logo} alt="BG" className="BG-login-register"></img>
                </div>
                <div className="register-cont">
                    <img src={logo} alt="Logo" className="logo-register" />
                    <h2 className="brand-title-register">Cafecito</h2>
                    <br></br>
                    <h4 className="register-title">Registro</h4>
                    <form className="register-form" onSubmit={handleSubmit}>
                        <label htmlFor="user-name-rg">Nombre Completo</label>
                        <input type="text" id="user-name-rg" name="fullName" value={formValues.fullName} onChange={handleChange}></input>
                        {errors.fullName && <div className="error-message">{errors.fullName}</div>}
                        
                        <label htmlFor="user-email-rg">Correo electronico</label>
                        <input type="email" id="user-email-rg" name="email" value={formValues.email} onChange={handleChange}></input>
                        {errors.email && <div className="error-message">{errors.email}</div>}
                        
                        <label htmlFor="pass-email-rg">Contraseña</label>
                        <input type="password" id="pass-email-rg" name="password" value={formValues.password} onChange={handleChange}></input>
                        {errors.password && <div className="error-message">{errors.password}</div>}
                        
                        <label htmlFor="snd-pass-email-rg">Repetir Contraseña</label>
                        <input type="password" id="snd-pass-email-rg" name="repeatPassword" value={formValues.repeatPassword} onChange={handleChange}></input>
                        {errors.repeatPassword && <div className="error-message">{errors.repeatPassword}</div>}
                        
                        <button type="submit">Registrarme!</button>
                        
                        <div className="checkbox-container">
                            <input type="checkbox" id="agree" name="agree" checked={formValues.agree} onChange={handleChange}></input>
                            <label htmlFor="agree" className="terms-cond">Acepto los Términos de servicio y la Política de privacidad, incluida la política de Uso de Cookies.</label>
                        </div>
                        {errors.agree && <div className="error-message">{errors.agree}</div>}
                    </form>
                    <br></br><br></br><br></br><br></br>
                </div>
            </div>
            <Footerpage />
        </div>
    );
}

export default Register;
